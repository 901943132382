import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HowItWorks1 from "../sections/how-it-works/how-it-works-1"
import HowItWorks2 from "../sections/how-it-works/how-it-works-2"
import HowItWorks3 from "../sections/how-it-works/how-it-works-3"
import HowItWorks4 from "../sections/how-it-works/how-it-works-4"

const HowItWorksPage = () => {
  return (
    <Layout>
      <Seo
        title="How It Works | A Proven Methodology"
        description="The Live Gamefully® methodology uses the psychology of game play to achieve epic wins in all of life. It’s backed by science."
        keywords="Proven methodology, how it works"
      />
      <HowItWorks1 />
      <HowItWorks2 />
      <HowItWorks3 />
      <HowItWorks4 />
    </Layout>
  )
}

export default HowItWorksPage
