import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Image, Row } from "react-bootstrap"
import HostChallenge1 from "../../images/host-challenge-1.svg"
import HostChallenge2 from "../../images/host-challenge-2.svg"
import HostChallenge3 from "../../images/host-challenge-3.svg"
import HostChallenge4 from "../../images/host-challenge-4.svg"
import HostChallenge5 from "../../images/host-challenge-5.svg"
import VideoPopup from "../../components/VideoPopup"

const HowItWorks2 = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [videoLink , setVideoLink] = React.useState("https://vimeo.com/841924819/14b0a296c6");
  const data1 = [
    {
      size: 4,
      title: "Add player emails",
      img: HostChallenge1,
    },
    {
      size: 4,
      title: "Create a squad",
      img: HostChallenge2,
    },
    {
      size: 4,
      title: "Select a Challenge (or create your own)",
      img: HostChallenge3,
    },
  ]

  const data2 = [
    {
      size: 4,
      title: "Send invitation ",
      img: HostChallenge4,
    },
    {
      size: 4,
      title: "Monitor progress",
      img: HostChallenge5,
    },
  ]
  return (
    <section id="works-section2" className="position-relative py-5">
      <VideoPopup
        videoId={videoLink}
        id="RenYleoiQHo"
        show={modalShow}
        onHide={() => { setVideoLink(""); setModalShow(false)}}
      />
      <div className="container py-3">
        <Row>
          <Col md="12">
            <h1>Host squad challenges in minutes</h1>
            <h5>The web-based Host account makes it easy</h5>
          </Col>
          <Col md="12">
            <Row>
              {data1.map((item, i) => (
                <Col
                  key={"steps-" + i}
                  md={item.size}
                  className="d-flex justify-content-center flex-column align-items-center"
                >
                  <Image
                    src={item.img}
                    quality={100}
                    formats={["AUTO", "WEBP"]}
                    placeholder={item.title}
                    alt={item.title}
                    className="img-fluid about-mira-01"
                  />
                  <p className="step">{item.title}</p>
                </Col>
              ))}
            </Row>
            <Row className="justify-content-center">
              {data2.map((item, i) => (
                <Col
                  key={"steps-2-" + i}
                  md={item.size}
                  className="d-flex justify-content-center flex-column align-items-center"
                >
                  <Image
                    src={item.img}
                    quality={100}
                    formats={["AUTO", "WEBP"]}
                    placeholder={item.title}
                    alt={item.title}
                    className="img-fluid about-mira-01"
                  />
                  <p className="step">{item.title}</p>
                </Col>
              ))}
            </Row>
          </Col>
          <Col md="12" className="mt-2">
            <p>
              Squad challenges bring the SuperBetter methodology to life. Each
              has 5-days of activities and an epic win. Select from a library of
              90+ challenges or create your own to have squads go for any epic
              win you choose.
            </p>
            <p>
              Squad members play about 10 minutes a day on the SuperBetter
              mobile or web app. They use a free Player account or a low-cost
              Hero account.
            </p>
          </Col>
          <Col
            md="12"
            className="d-flex justify-content-center align-items-center py-5"
          >
            <button
              style={{
                border: "2px solid #D86E4B",
                color: "#D86E4B",
                width: 210,
                height: 50,
                borderRadius: 50,
                fontWeight: 700,
                fontSize: 16,
                marginRight:10
              }}
              className="d-flex bg-white justify-content-center align-items-center "
              onClick={() => {setVideoLink("https://vimeo.com/841924819/14b0a296c6"); setModalShow(true)}}
            >
              Host Account Demo
              <StaticImage
                src="../../images/play-btn-red.svg"
                className="ms-2"
              />
            </button>

            <button
              style={{
                border: "2px solid #D86E4B",
                color: "#D86E4B",
                width: 210,
                height: 50,
                borderRadius: 50,
                fontWeight: 700,
                fontSize: 16,
              }}
              className="d-flex bg-white justify-content-center align-items-center "
              onClick={() => {setVideoLink("https://vimeo.com/868073339/2087ddd1a2?share=copy"); setModalShow(true)}}
            >
             Player Account Demo
              <StaticImage
                src="../../images/play-btn-red.svg"
                className="ms-2"
              />
            </button>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default HowItWorks2
