import { Link } from "gatsby"
import React from "react"
import { Col, Row } from "react-bootstrap"

const HowItWorks4 = () => {
  return (
    <section id="works-section4" className="position-relative py-5">
      <div className="container py-3">
        <Row>
          <Col md="12">
            <h1>SuperBetter is affordable</h1>
            <p>It’s our way of impacting more people</p>
            <Link
             to="/pricing"
              className="see-pricing"
            >
           See Pricing
            </Link>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default HowItWorks4