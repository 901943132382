import React from "react"
import { Col, Row } from "react-bootstrap"

const HowItWorks3 = () => {
  const data = [
    {
      bgColor: "#E3D7F0",
      title: "School",
      features: ["K-12 Schools", "Higher Ed", "Youth Mentoring"],
    },
    {
      bgColor: "#FFEDD1",
      title: "Work",
      features: ["Small Employers", "Large Employers", "Coaches"],
    },
    {
      bgColor: "#E0EEFF",
      title: "Community",
      features: [
        "Play with Friends",
        "Small Groups or Clubs",
        "Play with Family",
      ],
    },
    {
      bgColor: "#E1EDBF",
      title: "Care",
      features: ["Counseling Practices", "Youth Programs", "Health  Coaching"],
    },
    {
      bgColor: "#FDD9D9",
      title: "Sports",
      features: ["Youth Sports", "Adult Sports", "Fitness Classes"],
    },
  ]
  return (
    <section id="works-section3" className="position-relative py-5">
      <div className="container py-3">
        <Row>
          <Col md="12">
            <h1>Squad Play is a versatile platform</h1>
            <h5>
            Engage groups of any size to boost their mental health, resilience, wellbeing and skills for success.
            </h5>
            {/* <p >
            Select from a library of 90+ squad challenges to promote mental health, emotional wellbeing, social relationships & physical resilience – or design your own challenges!
            </p> */}
            <p>Here are some of the places it can be used:</p>
          </Col>
          <Col md="12" className="mb-3">
            <div className="row gap-5 row-cols-1 row-cols-md-6 row-cols-lg-6 d-flex justify-content-center mt-5">
              {data.map((item, i) => (
                <Col
                  className="card shadow-none"
                  key={"card-" + i}
                  style={{ backgroundColor: item.bgColor }}
                >
                  <h1>{item.title}</h1>
                  {item.features.map((obj, k) => (
                    <div className="tile" key={"features-" + i + "card-" + k}>
                      {obj}
                    </div>
                  ))}
                </Col>
              ))}
            </div>
          </Col>
          <Col md="12">
            <a
              href={`${process.env.HOST_APP}signup`}
              className="host-challenge"
              target="_blank"
              rel="noopener noreferrer"
            >
              30 Day Free Trial
            </a>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default HowItWorks3
