import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Row } from "react-bootstrap"

const HowItWorks1 = () => {
  return (
    <section id="works-section1" className="position-relative py-5 mt-5">
      <div className="container py-5 mt-5">
        <Row>
          <Col md="12">
            <h1> A proven methodology</h1>
            <p>
              The SuperBetter Live Gamefully® methodology is an evidence-based
              framework that uses the strengths of game play in all of life
            </p>
            <p>Playing SuperBetter means bringing a gameful mindset to everyday life. Players develop new skills, strengthen their whole-person resilience and track how their gameful strengths grow over time.</p>
            <p>7 rules for playing SuperBetter</p>
            <div style={{ marginTop: "150px" }}>
              <StaticImage
                src="../../images/how-it-work-bg-1.png"
                quality={100}
                formats={["AUTO", "WEBP"]}
                placeholder="The Live Gamefully® Methodology"
                alt="The Live Gamefully® Methodology"
                className="img-fluid d-none d-md-block"
              />
              <StaticImage
                src="../../images/how-it-work-mobile-1.svg"
                quality={100}
                formats={["AUTO", "WEBP"]}
                placeholder="The Live Gamefully® Methodology"
                alt="The Live Gamefully® Methodology"
                className="img-fluid d-block d-md-none my-3"
              />
            </div>
            <Link to="/the-science" className="backed-science">
              Backed by Science
            </Link>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default HowItWorks1
